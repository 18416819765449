@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

.home {
    background-color: #E6E6FA;
    height: 90vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* row-gap: 5vh; */
}

.med-possible-branding {
    font-size: 3rem;
    font-family: 'Satisfy', sans-serif;
    color: black;
}

.coming-soon {
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    color: black;
    text-align: center;
}

.coming-soon-label {
    font-weight: 200;
}

.coming-soon-date {
    font-weight: 400;
}

@media screen and (max-width: 960px) {
    .home {
        height: 70vh;
    }
}