.navbar {
    background-color: #E6E6FA;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: #00000014 0px 1px 12px;
}

.navbar-logo {
    justify-self: start;
    margin: 1rem;
    color: black;
    border: 1.5px solid black;
    border-radius: 50%;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6.5vh;
    height: 6.5vh;
    text-decoration: none;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    list-style: none;
    text-align: center;
    width: 100%;
    justify-content: end;
}

.nav-links {
    margin: 0 1rem;
    color: black;
    font-size: 1.75rem;
}

.nav-links:hover {
    color: red;
}

@media screen and (max-width: 960px) {
    .navbar {
        display: grid;
        height: 20vh;
        width: 100vw;
    }

    .navbar-brand {
        display: grid;
        justify-content: center;
        align-items: center;
        height: 10vh;
    }

    .navbar-logo {
        height: 6.5vh;
        width: 6.5vh;
    }

    .nav-menu {
        display: grid;
        list-style: none;
        width: 100vw;
        height: 10vh;
        justify-content: center;
        align-items: center;
        background-color: black;
    }

    .nav-links {
        color: #E6E6FA;;
    }
}